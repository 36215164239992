import React from 'react';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <div>404 - Not found.</div>
  </Layout>
);

export default NotFound;
